.logout {
  min-height: 300px;
  text-align: center;

  &__status {
    min-height: 40px;
  }

  &__text {
    display: inline-block;

    &--loading {
      margin-left: 10px;
    }
  }

  &__options {
    margin-top: 30px;
  }
}
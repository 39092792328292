.auth {
  margin-top: 100px;

  &__content {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  &__footer {
    margin-top: 50px;
    text-align: center;

    & a {
      display: inline-block;

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }
}
.add-team-member {
  &__form {
    & > *:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__groups {
    margin-top: 20px;
  }
}

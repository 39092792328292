@import "../../styles/variables";

.segment {
  &:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: thin solid #ccc;
  }

  &__time {
    padding-bottom: 20px;
  }

  &__player {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__download {
    padding-left: 20px;
  }

  @media ($breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__time {
      padding-bottom: 0;
      padding-right: 20px;
    }

    &__player {
      flex: 0 0 auto;
      width: 45%;
    }
  }
}
@import "../../../styles/variables";

.headline-options {
  margin-bottom: 10px;

  &__left {
    margin-bottom: 10px;

    h1, h2, h3, h4 {
      margin-bottom: 0;
    }
  }

  @media ($breakpoint-md) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &__left {
      margin-bottom: 0;
    }
  }
}
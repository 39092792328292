.page {
    max-width: 1220px;
    margin: 0 auto;
    padding: 30px 10px;

    &__version {
        margin-top: 10px;
        text-align: center;
        color: #cccccc;
    }
}